import React from "react";
import { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom'

import { ReactComponent as ProductionUp } from './production-up.svg';
import { ReactComponent as SandboxUp } from './sandbox-up.svg';
import { ReactComponent as Error } from './exclamation.svg';

import host from './host'

function Details({ addToast }) {

    let { botId } = useParams();

    const [bot, setBot] = useState()

    const [loading, setLoading] = useState(true)

    const [sandbox, setSandbox] = useState(false)

    function toggleSandbox() {
        setSandbox(!sandbox)
    }

    const [productionBot, setProductionBot] = useState()

    const [sandboxBot, setSandboxBot] = useState()

    useEffect(() => {

        setLoading(true)

        async function fetchInfo() {

            const response = await fetch(`${host}/api/bot/${botId}`)
            if (response.ok) {
                const data = await response.json()

                console.log(data)

                if (data.success) {
                    setProductionBot(data.bot.productionBot)
                    setSandboxBot(data.bot.sandboxBot)

                } else {
                    console.log(data.status)

                    addToast({ status: 'danger', message: 'Details did not load' })
                }
            } else {
                addToast({ status: 'danger', message: 'There was a problem' })
            }

            setLoading(false)
        }
        fetchInfo()
    }, [botId])

    if (loading) {
        return (
            <div class="text-center pt-64">
                <svg role="status" class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
            </div>
        );
    }

    return <Info bot={sandbox ? sandboxBot : productionBot} sandbox={sandbox} toggleSandbox={toggleSandbox} />
}

function Info({ bot, sandbox, toggleSandbox }) {
    const styles = {
        parent: {
            display: "none"
        }
    };

    return (
        <div className="pt-12 pl-8 pr-8 max-w-max">
            <nav class="flex" aria-label="Breadcrumb">
                <ol class="inline-flex items-center space-x-1 md:space-x-3">
                    <li class="inline-flex items-center">
                        <Link to="/bots" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                            <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                            Overview
                        </Link>
                    </li>
                    <li>
                        <div class="flex items-center">
                            <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                            <Link to={`/bot/${bot.id}`} class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">{bot.name}</Link>
                        </div>
                    </li>
                </ol>
            </nav>
            <div className="pt-8 pb-8">
                <div className="flex items-center">
                    {/* {sandbox ?
                        <div className="flex justify-between pr-4 mb-2">
                            {bot.sandboxStatus === "up" && <SandboxUp className="ml-4" />}
                            {bot.sandboxStatus === "down" && <Error className="ml-4" />}
                        </div>
                        :
                        <div className="flex justify-between pr-4 mb-2">
                            {bot.productionStatus === "up" && <ProductionUp className="ml-4" />}
                            {bot.productionStatus === "down" && <Error className="ml-4" />}
                        </div>
                    } */}

                    <h1 className="mb-2 text-3xl font-bold text-gray-900 dark:text-white pr-8">{bot.name}</h1>

                    <label for="toggle-example" class="flex relative items-center cursor-pointer mb-1 pr-8">
                        <input onClick={toggleSandbox} type="checkbox" id="toggle-example" checked={sandbox} class="sr-only" />
                        <div class="w-11 h-6 bg-gray-200 rounded-full border border-gray-200 toggle-bg dark:bg-gray-700 dark:border-gray-600"></div>
                        <span class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Toggle sandbox</span>
                    </label>
                </div>
                <div className="pt-4">
                    {bot.assets.success ?
                        <div>
                            <div className="xl:grid grid-cols-8 grid-rows-8 grid-flow-row auto-rows-min gap-4 pt-4">
                                <ROI profitLoss={bot.profitLoss} />
                                <Orders orders={bot.orders} />
                                {bot.version === 'v2' ?
                                    <AssetPercentages assets={bot.assets} />
                                    :
                                    <>
                                        <AUM assets={bot.assets} />
                                        <Assets assets={bot.assets} />
                                    </>

                                }
                                {/* <Trades trades={bot.trades} /> */}
                                <Datafeed data={bot.data} />
                            </div>
                        </div>
                        :
                        <div class="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800" role="alert">
                            <span class="font-medium">Info:</span> No info available for production. Bot is not allowed to view production info yet.
                        </div>
                    }
                </div>
            </div>

        </div>
    );
}

function ROI({ profitLoss }) {
    return (
        <div className="block col-span-2 row-span-2 p-6 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mb-4 xl:mb-0">
            <div className="box-border">

            </div>
            <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white pb-6">Return on investment</h5>
            <h5 className={`text-6xl font-bold text-${Math.round(profitLoss * 10) / 10 >= 0 ? 'green-500' : 'red-500'} dark:text-white pb-6`}>{Math.round(profitLoss * 10) / 10}%</h5>
        </div>
    );
}

function Trades({ trades }) {

    return (
        <div className="block col-span-6 row-span-4 p-6 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 max-h-[28rem] mb-4 xl:mb-0">
            <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white pb-3">Trades</h5>
            <div class="flex flex-col max-h-[22rem]">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
                        <div class="overflow-hidden sm:rounded-lg">
                            <table class="min-w-full">
                                <thead class="bg-gray-50 dark:bg-gray-700">
                                    <tr>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Asset
                                        </th>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Entry Price
                                        </th>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Exit Price
                                        </th>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Asset percent
                                        </th>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            P/L
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trades.sort(function (a, b) { return new Date(b.createdAt) - new Date(a.createdAt); }).map(trade => (
                                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {trade.asset}
                                            </td>
                                            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {trade.open.toPrecision(5)}
                                            </td>
                                            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {trade.close.toPrecision(5)}
                                            </td>
                                            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {trade.percentage}%
                                            </td>
                                            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {trade.profitLoss >= 0 ?
                                                    <span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">{Math.round(trade.profitLoss * 100) / 100}%</span>
                                                    :
                                                    <span class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">{Math.round(trade.profitLoss * 100) / 100}%</span>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ROIGraph({ profitLoss }) {
    return (
        <div className="block col-span-2 row-span-2 p-6 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white pb-6">Return on investment</h5>
            <h5 className={`text-6xl font-bold text-${profitLoss >= 0 ? 'green-400' : 'red-500'} dark:text-white pb-6`}>{profitLoss}%</h5>
        </div>
    );
}

function Orders({ orders }) {
    return (
        <div className="block col-span-6 row-span-4 p-6 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 max-h-[36rem] mb-4 xl:mb-0">
            <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white pb-3">Orders</h5>
            <div class="flex flex-col max-h-[28rem]">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
                        <div class="overflow-hidden sm:rounded-lg">
                            <table class="min-w-full">
                                <thead class="bg-gray-50 dark:bg-gray-700">
                                    <tr>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Side
                                        </th>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Date & Time
                                        </th>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Price
                                        </th>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Symbol
                                        </th>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Quantity
                                        </th>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.sort(function (a, b) { return new Date(b.createdAt) - new Date(a.createdAt); }).map(order => (
                                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 cursor-pointer">
                                            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {order.side}
                                            </td>
                                            <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                {order.createdAt}
                                            </td>
                                            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {order.status === "FULFILLED" ? parseFloat(order.priceAvgExec).toPrecision(5) : parseFloat(order.limitPrice).toPrecision(5)}
                                            </td>
                                            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {order.baseAsset + order.quoteAsset}
                                            </td>
                                            {order.quantity === null ?
                                                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {order.qtyPct}%
                                                </td>
                                                :
                                                <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    ${(parseFloat(order.quantity) * parseFloat(order.priceAvgExec)).toPrecision(5)}
                                                </td>
                                            }

                                            <td class="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                {order.status === 'fullyCanceled' && <span class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">{order.status}</span>}
                                                {order.status === 'fulfilled' && <span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">{order.status}</span>}
                                                {order.status === 'acceptedByBEM' && <span class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">{order.status}</span>}
                                                {order.status === 'acceptedByExch' && <span class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">{order.status}</span>}
                                                {order.status === 'partiallyExecuted' && <span class="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900">{order.status}</span>}
                                                {order.status === 'rejectedByBEM' && <span class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">{order.status}</span>}
                                                {order.status === 'rejectedByExch' && <span class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">{order.status}</span>}
                                                {order.status === 'partiallyExecutedAndCanceled' && <span class="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900">{order.status}</span>}
                                                {order.status === 'FULFILLED' && <span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">{order.status}</span>}
                                                {order.status === 'CANCELED' && <span class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">{order.status}</span>}
                                                {order.status === 'OPEN' && <span class="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">{order.status}</span>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function AssetPercentages({ assets }) {
    return (
        <div className="block col-span-2 p-6 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mb-4 xl:mb-0">
            <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white pb-6">Asset percentages</h5>
            {assets.amounts.map(asset => (
                <div className="flex flex-row pb-4">
                    <h1 className="mb-2 text-4xl font-bold text-gray-900 dark:text-white pr-4">{Math.round(asset.amount)}%</h1>
                    <p className="font-normal text-gray-700 dark:text-gray-400 self-center">{asset.asset}</p>
                </div>
            ))}

        </div>
    );
}

function Assets({ assets }) {
    return (
        <div className="block col-span-2 row-span-4 p-6 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mb-4 xl:mb-0">
            <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white pb-6">Assets</h5>
            {assets.assets.map(asset => (
                <>
                    <div className="pb-4">
                        <div className="flex flex-row pb-4">
                            <h1 className="mb-2 text-4xl font-bold text-gray-900 dark:text-white pr-4">{Math.round(asset.percentage)}%</h1>
                            <p className="font-normal text-gray-700 dark:text-gray-400 self-center">{asset.asset}</p>
                        </div>
                        <div className="flex flex-row pb-4">
                            <p className="font-bold text-gray-700 dark:text-gray-400 self-center pr-1">{asset.total.toFixed(5)} {asset.asset}</p>
                            <p className="font-normal text-gray-700 dark:text-gray-400 self-center">= ${asset.value.toFixed(2)}</p>
                        </div>

                    </div>
                </>
            ))}

        </div>
    );
}

function AUM({ assets }) {
    return (
        <div className="block col-span-2 p-6 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mb-4 xl:mb-0">
            <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white pb-6">AUM</h5>

            <h1 className="mb-2 text-4xl font-bold text-gray-900 dark:text-white pr-4">
                ${assets.assets.reduce((prev, current) => { return prev + current.value }, 0).toFixed(2)}
            </h1>
        </div>
    );
}

function Datafeed({ data }) {

    return (
        <div className="block col-span-8 row-span-4 p-6 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 max-h-96">
            <h5 className="mb-2 text-2xl font-bold text-gray-900 dark:text-white pb-3">Data feed</h5>
            <div class="flex flex-col max-h-72">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
                        <div class="overflow-hidden sm:rounded-lg">
                            <table class="min-w-full">
                                <thead class="bg-gray-50 dark:bg-gray-700">
                                    <tr>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Date & Time
                                        </th>
                                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                            Info
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(dataPoint => (
                                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                {dataPoint.createdAt}
                                            </td>
                                            <td class="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white">
                                                {dataPoint.info}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Details