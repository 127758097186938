import React from "react";
import { useState, useEffect } from 'react'
import { Routes, Route } from "react-router-dom";

import Sidebar from "./Sidebar"
import Dashboard from "./Dashboard";
import Overview from './Overview'
import New from './New'
import Details from './Details'
import Charts from './Charts'
import Login from './Login'

import host from './host'

function App() {

    const [toasts, setToasts] = useState([])

    const [loaded, setLoaded] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)
    const [loadingAuth, setLoadingAuth] = useState(true)
    const [loading, setLoading] = useState(true)


    function addToast(toast) {
        setToasts([...toasts, toast])
    }

    function removeToast(toast) {
        setToasts(toasts.filter(t => t.name !== toast.name))
    }

    const [bots, setBots] = useState([])

    useEffect(() => {

        async function fetchAuth() {

            console.log(`Fetching ${host}`)

            const response = await fetch(`${host}/api/auth`)
            if (response.ok) {
                console.log(response)
                const data = await response.json()

                console.log(data)

                if (data.success) {
                    setLoggedIn(true)
                    setLoadingAuth(false)
                } else {
                    console.log(data.status)
                    setLoadingAuth(false)
                }
            }
        }
        fetchAuth()
    }, [])

    useEffect(() => {
        async function fetchBots() {
            const response = await fetch(`${host}/api/bots`)
            if (response.ok) {
                const data = await response.json()

                console.log(data)

                if (data.success) {
                    setBots(data.bots)
                    setLoaded(true)
                    setLoading(false)

                } else {
                    console.log(data.status)
                }
            }
        }

        fetchBots()
    }, [loggedIn])

    if (loadingAuth) {
        return (
            <div class="text-center pt-64">
                <svg role="status" class="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
            </div>
        );
    }

    return (
        <div className="bg-gray-50 flex flex-row h-screen overflow-y-hidden">
            {!loggedIn ?
                <Login setLoggedIn={setLoggedIn} addToast={addToast} />
                :
                <>
                    <Sidebar bots={bots} />
                    <div className='overflow-y-scroll w-full'>
                        <Routes>
                            <Route path="/" element={<Dashboard bots={bots} loading={loading} />} />
                            <Route path="/bots" element={<Overview bots={bots} loading={loading} />} />
                            <Route path="/bot/:botId" element={<Details addToast={addToast} />} />
                            <Route path="/bot/new" element={<New bots={bots} setBots={setBots} addToast={addToast} />} />
                            <Route path="/charts" element={<Charts loaded={loaded} />} />
                        </Routes>
                    </div>
                </>

            }

            <div className='absolute right-5 bottom-5'>
                {toasts.map(toast => (
                    <div id={`toast-${toast.status}`} className="flex w-72 items-center p-4 mb-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
                        {toast.status === 'success' ?
                            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                            </div>
                            :
                            <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </div>
                        }
                        <div className="ml-3 text-sm font-normal">{toast.message}</div>
                        <button type="button" onClick={() => { removeToast(toast) }} className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-collapse-toggle={`toast-${toast.status}`} aria-label="Close">
                            <span className="sr-only">Close</span>
                            <svg clasName="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default App;
